import UserResource from 'common/services/resources/user.js'

export default {
	props: [],
	data:
		function () {
			return {
				CONSTANTS: 
					{
						ACTIVATED_ACCOUNT : this.$gettext('Your account is activated.<br/>A confirmation email has been sent.'),
						ALREADY_ACTIVATED_ACCOUNT : this.$gettext('Your account is already activated.'),
						ACTIVATION_PROBLEM : this.$gettext('Problem when attempting to activate.'),
						WRONG_IMG: require('@/assets/images/picto_wrong.png'),
						OK_IMG: require('@/assets/images/picto_ok.png')
					},
				msg: '',
				img: ''
			}
		},
    created: function () {
		this.eventHub.$emit('hideNavbar');
		this.eventHub.$on('setActivateMsg',this.setActivateMsg);
		this.eventHub.$on('setActivateImg',this.setActivateImg);
		this.activate();
    },			
	methods: {
		activate: function(){
			var hash = this.$router.currentRoute.query.hash;
			var region = this.$router.currentRoute.query.region;
			var eventHub = this.eventHub;
			var CONSTANTS = this.CONSTANTS;
			var msg = CONSTANTS.ACTIVATION_PROBLEM;
			var img = CONSTANTS.WRONG_IMG;
			UserResource.activate(hash,region).then(
				function(response) {
					eventHub.$emit('setActivateMsg',CONSTANTS.ACTIVATED_ACCOUNT);
					eventHub.$emit('setActivateImg',CONSTANTS.OK_IMG);
				}).catch(
				function(error) { 
					if(error.response && error.response.status==409)
						msg = CONSTANTS.ALREADY_ACTIVATED_ACCOUNT;
					eventHub.$emit('setActivateMsg',msg);	
					eventHub.$emit('setActivateImg',img);	
					
			});
		},
		setActivateMsg: function(msg){
			this.msg = msg;
		},
		setActivateImg: function(img){
			this.img = img;
		}
	}
}
